import baseApiUrl from "@/utils/baseApiUrl";
import baseLocalApiUrlNextjs from "@/utils/baseLocalApiUrlNextjs";
import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import Image from "next/image";
import { useEffect } from "react";

export default function Certificate({ certificates }) {
  useEffect(() => {
    const swiperCertificate = document.querySelector(
      `swiper-container#swiper-Certificate`
    );
    const swiperCertificateParams = {
      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 2,
        },
        375: {
          slidesPerView: 1,
        },
      },
      pagination: {
        dynamicBullets: true,
      },
      loop: true,
      autoplay: {
        delay: 3000,
      },
      on: {
        init(swiper) {
          const lg = lightGallery(swiperCertificate, {
            plugins: [lgZoom, lgThumbnail],
          });
          // Before closing lightGallery, make sure swiper slide
          // is aligned with the lightGallery active slide
          swiperCertificate.addEventListener("lgBeforeClose", () => {
            swiper.slideTo(lg.index, 0);
          });
        },
      },
    };
    Object.assign(swiperCertificate, swiperCertificateParams);
    swiperCertificate.initialize();
  }, []);
  return (
    <div className="tw-container tw-mx-auto">
      <div className="tw-flex tw-items-center tw-justify-center tw-gap-x-2">
        <p className="tw-text-center tw-text-h1-base tw-font-bold tw-capitalize !tw-text-_red-600 dark:!tw-text-_red-700 !tw-mb-10">
          {certificates?.title}
        </p>
      </div>
      <swiper-container init="false" id="swiper-Certificate">
        {(certificates?.Certificates || []).map((certificate, index) => (
          <swiper-slide
            class="tw-flex tw-relative tw-h-full tw-w-auto tw-justify-center"
            data-src={`${baseApiUrl}${certificate?.image.data[0].attributes?.url}`}
            key={index}
          >
            <Image
              quality={90}
              overrideSrc={`${baseApiUrl}${certificate?.image.data[0].attributes?.url}`}
              src={`${baseLocalApiUrlNextjs}${certificate?.image.data[0].attributes?.url}`}
              alt={`${certificate?.image.data[0].attributes?.alternativeText}`}
              sizes="(max-width: 768px) 100vw, (max-width: 960px) 50vw, (max-width: 1200px) 33vw, 25vw"
              style={{ objectFit: "contain" }}
              className="tw-rounded-[1.25rem]"
              width={250}
              height={250}
            />
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
}
Certificate.defaultProps = {};
