import generateTitle from "@/utils/generateTitle";
import axios from "axios";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import baseApiUrl from "../../utils/baseApiUrl";
import baseLocalApiUrlNextjs from "../../utils/baseLocalApiUrlNextjs";
export default function Partners() {
  const  {locale}  = useRouter();
  const [partners, setPartners] = useState();
  useEffect(() => {
    const getPartners = async () => {
      const response = await axios.get(
        `${baseApiUrl}/api/partner?populate=partnerItem.image&locale=${locale}`
      );
      setPartners(response.data.data);
    };
    getPartners();
  }, [locale]);
  return (
    <div>
      <p
        className="main-title
         [&>span]:!tw-text-_red-600 [&>span]:dark:!tw-text-_red-700"
      >
        {generateTitle(partners?.attributes?.title)}
      </p>
      <div className="tw-mx-1 sm:tw-mx-2 md:tw-mx-3 lg:tw-mx-4 tw-p-1 sm:tw-p-2 md:tw-p-3 tw-grid tw-grid-cols-2 md:tw-grid-cols-6 tw-gap-2 sm:tw-gap-3 tw-justify-items-center">
        {partners?.attributes.partnerItem.map((partner, index) => (
          <div
            key={index}
            className="tw-bg-white dark:tw-bg-_black-600 tw-rounded-3xl tw-shadow-md tw-p-2 tw-w-full"
          >
            <div className="tw-aspect-[4/3] tw-relative">
              <Image
                quality={90}
                overrideSrc={`${baseApiUrl}${partner.image.data.attributes.url}`}
                src={`${baseLocalApiUrlNextjs}${partner.image.data.attributes.url}`}
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                style={{ objectFit: "contain" }}
                alt={`partner ${index + 1}`}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
