import Navbar from '@/components/Layout/HomeNavbar';
import Footer from '@/components/Layout/MyFooter';
import Switch from '@/components/Switch/Switch';

export default function Layout({ pageProps, children }) {
  return (
    <>
      <Switch />
      {<Navbar pageProps={pageProps}/>}
      <div className="tw-relative">
        <div className="dark:tw-bg-_black-900">{children}</div>
      </div>
      {/* <div className="tw-relative">
        <Letter />
      </div> */}
      <Footer />
    </>
  );
}
