import baseApiUrl from "@/utils/baseApiUrl";
import baseLocalApiUrlNextjs from "@/utils/baseLocalApiUrlNextjs";
import generateTitle from "@/utils/generateTitle";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
export default function SmartHomeSolutions({ solutions }) {
  const router = useRouter();
  const { locale } = router;

  return (
    <div className="tw-container tw-mx-auto ">
      <p
        className="tw-text-h1-base tw-text-center tw-font-bold tw-justify-center tw-flex tw-mb-10 tw-capitalize !tw-text-_red-600 
                   dark:tw-text-white [&>span]:!tw-text-_red-600 [&>span]:dark:!tw-text-_red-700"
      >
        {generateTitle(solutions.title)}
      </p>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 md:tw-items-stretch tw-pt-10 ">
        {solutions.smartHomeSolution.map((solution) => (
          <div
            key={solution.id}
            className="tw-relative tw-h-[450px] md:tw-h-[490px]"
          >
            <div
              className={`
                tw-absolute tw-inset-0 dark:tw-bg-black-700 tw-py-6 tw-px-6 tw-rounded-lg 
                tw-text-black-700 dark:tw-text-white tw-flex tw-flex-col dark:tw-shadow-sm dark:tw-shadow-gray-400
                ${
                  solution.bestSale
                    ? "md:tw-col-start-2 md:tw-row-start-1 tw-transform md:-tw-translate-y-5 md:tw-scale-110 tw-z-[5] tw-shadow-lg tw-bg-gray-200  "
                    : "tw-bg-gray-200"
                }
              `}
            >
              <div className="">
                <div className="tw-flex tw-justify-center tw-items-center tw-my-4 md:tw-my-8 tw-h-[120px]">
                  <div className="tw-relative tw-w-[180px] tw-h-[130px] md:tw-w-[190px] md:tw-h-[150px]">
                    <Image
                      quality={90}
                      overrideSrc={`${baseApiUrl}${solution.image?.data?.attributes?.url}`}
                      src={`${baseLocalApiUrlNextjs}${solution.image?.data?.attributes?.url}`}
                      alt={solution.title}
                      fill
                      className="tw-object-contain"
                      loading="lazy"
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    />
                  </div>
                </div>
                <div className="tw-min-h-[100px] tw-max-h-[180px] tw-overflow-y-auto tw-pt-2 tw-scrollbar-hide tw-flex tw-content-center">
                  <p
                    className={`${solution.bestSale ? "tw-text-lg tw-text-gray-800 dark:tw-text-white" : " tw-text-gray-800 dark:tw-text-white"}`}
                  >
                    {solution.description}
                  </p>
                </div>
              </div>
              <div className="tw-mt-auto">
                <Link
                  href={`${solution.url}`}
                  className="tw-block tw-bg-red tw-text-white hover:tw-bg-red hover:tw-text-white dark:tw-bg-_red-700 hover:dark:tw-bg-white dark:tw-text-white hover:dark:tw-text-_red-700 tw-py-2 tw-px-4 tw-rounded tw-text-center tw-font-bold tw-uppercase tw-text-sm"
                >
                  {locale === "vi" ? "Tìm hiểu thêm" : "Read More"}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
