import { Fragment } from "react";

const generateTitle = (title) => {
  if (title && typeof title === "string") {
    const chosenTexts = title
      .split("|")
      .map((str) => str.trim())
      .filter((str) => str !== "");

    if (chosenTexts.length > 0) {
      let start = 0;
      let restText = <Fragment></Fragment>;

      const texts = chosenTexts?.map((chosenText, index) => {
        const text = (
          <Fragment>
            <span
              className={`tw-inline-block tw-px-[0.25rem] tw-text-${index == 0 ? "base" : "lg"}`}
            >
              {" "}
              {`${chosenText} `}
            </span>
          </Fragment>
        );

        return text;
      });

      return [...texts, restText].map((text, index) => (
        <Fragment key={index}> {text}</Fragment>
      ));
    }

    return title;
  }
};

export default generateTitle;
