import baseApiUrl from "@/utils/baseApiUrl";
import baseLocalApiUrlNextjs from "@/utils/baseLocalApiUrlNextjs";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect } from "react";
export default function Banner({ nameSwiper, banners }) {
  useEffect(() => {
    const swiperEl = document.querySelector(`swiper-container#${nameSwiper}`);
    const swiperParams = {
      slidesPerView: 1,
      pagination: { dynamicBullets: true },
      loop: true,
      autoplay: {
        delay: 5000,
      },
    };

    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }, []);
  const router = useRouter();
  const { locale } = router;
  return (
    <div className="tw-relative tw-overflow-hidden">
      <div className="tw-h-auto tw-w-full tw-aspect-[32/15]">
        <swiper-container init="false" id={nameSwiper} class="tw-h-full">
          {banners?.map((banner, index) => {
            return (
              <swiper-slide key={index} class="tw-h-full tw-overflow-hidden ">
                <div className="tw-relative tw-h-full tw-w-full">
                  <Image
                    className="image-bg"
                    overrideSrc={`${baseApiUrl}${banner?.imageBg?.src}`}
                    src={`${baseLocalApiUrlNextjs}${banner?.imageBg?.src}`}
                    alt={banner?.imageBg?.alt}
                    loading="lazy"
                    sizes="100vw"
                    quality={90}
                    fill
                    style={{
                      objectFit: "fill",
                    }}
                  />
                </div>
              </swiper-slide>
            );
          })}
        </swiper-container>
      </div>
    </div>
  );
}

Banner.defaultProps = {
  nameSwiper: "swiper-banner",
  banners: [],
};
